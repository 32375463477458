package com.showbigtext

import androidx.compose.animation.core.*
import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.*
import androidx.compose.material.icons.Icons
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.constraintlayout.compose.ConstraintLayout
import showbigtext.composeapp.generated.resources.*
import com.showbigtext.theme.AppTheme
import com.showbigtext.theme.LocalThemeIsDark
import compose.icons.FeatherIcons
import compose.icons.feathericons.Sun
import kotlinx.coroutines.isActive
import org.jetbrains.compose.resources.Font
import org.jetbrains.compose.resources.stringResource
import org.jetbrains.compose.resources.vectorResource

@Composable
internal fun App() {
    var isDarkTheme by remember { mutableStateOf(true) }
    AppTheme(
        isDarkTheme = isDarkTheme
    ) {
        ConstraintLayout(
            modifier = Modifier
                .fillMaxSize()
                .then(
                    if (getPlatform() != "WEB") {
                        Modifier.padding(
                            start = 8.dp,
                            end = 8.dp,
                            top = 24.dp,
                            bottom = 32.dp
                        )
                    } else {
                        Modifier
                    }
                )
                .imePadding(),
            animateChanges = true
        ) {
            val (textField, minButton, maxButton, themeButton) = createRefs()

            var text by remember { mutableStateOf("") }
            var textSize by remember { mutableIntStateOf(48) }

            TextField(
                value = text,
                onValueChange = {
                    text = it
                },
                modifier = Modifier
                    .constrainAs(textField) {
                        top.linkTo(parent.top)
                        start.linkTo(parent.start)
                        end.linkTo(parent.end)
                        bottom.linkTo(parent.bottom)
                    }
                    .fillMaxSize(),
                textStyle = TextStyle(fontSize = textSize.sp),
                placeholder = {
                    Text(
                        modifier = Modifier.fillMaxSize(),
                        text = "Type in your text here",
                        fontSize = textSize.sp,
                        style = TextStyle.Default.copy(
                            lineHeight = textSize.sp
                        )
                    )
                },
                /*colors = TextFieldDefaults.colors().copy(
                    unfocusedContainerColor = if (isDarkTheme) DarkThemeColors.inverseOnSurface else LightThemeColors.inverseOnSurface,
                    focusedContainerColor = if (isDarkTheme) DarkThemeColors.inverseOnSurface else LightThemeColors.inverseOnSurface,
                    focusedIndicatorColor = Color.Transparent,
                    unfocusedIndicatorColor = Color.Transparent
                )*/
            )

            Button(
                onClick = {
                    isDarkTheme = !isDarkTheme
                },
                modifier = Modifier.constrainAs(themeButton) {
                    bottom.linkTo(textField.bottom, 4.dp)
                    end.linkTo(textField.end, 4.dp)
                }
            ) {
                Icon(imageVector = FeatherIcons.Sun, contentDescription = null)
            }

            TextButton(
                onClick = {
                    textSize += 8
                },
                modifier = Modifier.constrainAs(maxButton) {
                    bottom.linkTo(textField.bottom, 4.dp)
                    end.linkTo(themeButton.start, margin = 4.dp)
                }
            ) {
                Text(
                    text = "+",
                    fontSize = 22.sp,
                    fontWeight = FontWeight.Bold
                )
            }

            TextButton(
                onClick = {
                    textSize -= 8
                },
                modifier = Modifier.constrainAs(minButton) {
                    bottom.linkTo(textField.bottom, 4.dp)
                    end.linkTo(maxButton.start, margin = 4.dp)
                }
            ) {
                Text(
                    text = "-",
                    fontSize = 22.sp,
                    fontWeight = FontWeight.Bold
                )
            }
        }
    }
}
